@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

html,
body,
#root,
.App {
  height: 100%;
  font-family: "Ubuntu";
}

*:focus {
  outline: none;
}

.dbx-input-box {
  width: 100%;
}

.dbx-text-input {
  width: 100%;
  padding: 0px 0px 5px 5px;
  font-weight: bold;
  color: #313131;
  text-align: left;
}

.dbx-container-content {
  height: calc(100% - 280px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/mapdotted.98b66e98.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.dbx-size-logo {
  width: 180px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.dbx-search-container {
  height: 110px;
  width: 550px;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px 8px;
}

.error-text {
  font-weight: bold;
  height: 30px;
  padding-top: 5px;
}

.dbx-search-input {
  background-color: white;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 50px;
  padding: 10px;
  border-radius: 15px;
  width: 100%;
  text-align: center;
}

.loader-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dbx-input-container {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.dbx-button-search {
  height: 50px;
  width: 70px;
  background-color: #313131;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  cursor: pointer;
  margin-left: 3px;
}

.dbx-icon-search {
  width: 25px;
  height: 25px;
}

.dbx-top-bar {
  height: 160px;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: absolute;
  z-index: -1;
}

.dbx-logo-box {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #ffffff;
  width: 320px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.bad-text {
  font-weight: bold;
}

.dbx-logo-position {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dbx-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  background-color: #313131;
  text-align: center;
  padding: 0 10rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dbx-info-box {
  color: #ffffff;
  text-align: right;
  padding: 1rem;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dbx-info-title {
  font-weight: bold;
  line-height: 1;
}

.dbx-info-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.dbx-name-company {
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 1rem;
}

.dbx-tag-client {
  padding: 0rem 0rem;
  cursor: pointer;
}

.dbx-company-position {
  text-align: left;
}

.dbx-content-center {
  background-color: red;
}

.current-state {
  background-color: #00ec79 !important;
}

.box-list {
  display: flex;
  flex-direction: row;
  background-color: white;
  width: 600px;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  min-height: 40px;
  border: 1px solid #f5f5f5;
  align-items: center;
  padding: 10px 10px;
  margin-top: 10px;
}

.current-state-icon {
  width: 20px;
  height: 20px;
}

.box-icon {
  margin-right: 10px;
}

.ajust-result-track {
  justify-content: flex-start;
  padding-top: 40px;
  overflow-x: scroll;
}

.content-list-track {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 15px;
}

.text-track {
  text-align: left;
  line-height: 1;
  width: 100%;
}

.date-time-track {
  font-weight: bold;
  font-size: 14px;
  padding-top: 10px;
  width: 100%;
  text-align: right;
}

.back-button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.box-guide {
}

@media (min-width: 320px) and (max-width: 480px) {
  .logo-ajust {
    width: 90%;
  }

  .box-list {
    display: flex;
    flex-direction: row;
    background-color: white;
    width: 90%;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    min-height: 40px;
    border: 1px solid #f5f5f5;
    align-items: center;
    padding: 10px 10px;
  }

  .ajust-mobile {
    height: 100%;
  }

  .loader-content {
    height: calc(100% - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .dbx-search-container {
    height: 110px;
    background-color: #f49400;
    width: 100%;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    min-height: 110px;
  }

  .dbx-text-input {
    width: 100%;
    padding: 0px 0px 5px 5px;
    font-weight: bold;
    color: #313131;
    text-align: left;
    font-size: 14px;
  }

  .dbx-container-content {
    min-height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image: url(/static/media/mapdotted.98b66e98.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    padding-top: 1rem;
  }

  .dbx-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #313131;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .dbx-footer-and {
    z-index: 100;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: #313131;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  .dbx-name-company {
    color: #ffffff;
    font-weight: bold;
    font-size: 0.8rem;
    padding: 1rem;
    font-size: 10px;
  }

  .dbx-info-text {
    font-size: 9px;
  }

  .dbx-info-box {
    color: #ffffff;
    text-align: right;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    padding-right: 10px;
  }

  .dbx-info-footer {
    display: flex;
    flex-wrap: inherit;
    padding-top: 13px;
    flex-direction: column;
    justify-content: center;
  }

  .dbx-info-title {
    font-size: 10px;
  }

  .dbx-logo-box {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #ffffff;
    width: 44%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .dbx-tag-client {
    padding: 0rem 0rem;
    cursor: pointer;
  }

  .dbx-top-bar {
    background-color: #f49400;
    height: 90px;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    position: absolute;
    z-index: -1;
  }

  .dbx-footer-mobile {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    background-color: $footerbg;
    text-align: center;
    height: 70px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

